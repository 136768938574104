import {
  bannerGetTopic, getChapter, GetChapterNumApi, getChapterTopicInfo, getExamId,
} from '~/api/exam';

export const usePracticeStore = defineStore('print', {
  state: () => {
    return {
      status: '',  // 学习状态  'unMake':未制定  'made':制定了 ---- 'learned' 已学习状态
      testDate: '', // 考试时间
      reviewTime: 0, // 每天复习时间
      exerciseTopicNum: 0, //每天练习题目个数
      testRemainDays: 0, // 考试剩余天数
      allSubjectPlan: {}, // 当前题库下的所有科目的学习计划
      todayRemainTopicNum: 0, // 今天剩余做题个数
      todayRemainTime: 0, // 今天剩余复习时间， 如果题目已做完 就显示说明已完成
    }
  }, actions: {
    // 初始化学习计划数据
    async planDataInit() {
      const { keMuID, tiKuID, buyID, linktid } = getCookie('relativeInfo');
      // const res = await GetChapterNumApi({ ID:  tiKuID || keMuID });
      const responseData = await GetChapterNumApi({ ID: keMuID });
      const { ChapterExamNum, ChapterExamDone } = responseData.DataList[0];
      
      if (this.allSubjectPlan.length && this.allSubjectPlan[keMuID]) {
        if (+ChapterExamNum) {
          const { todayRemainTopicNum, todayRemainTime, status } = this.allSubjectPlan[keMuID]
          this.status = status;
          this.allSubjectPlan[keMuID].topicNeedNumPerDay = Math.ceil(ChapterExamNum - ChapterExamDone) / this.getTestRemainDays; // 这里获取每天需要做的题目数量
          if (!todayRemainTopicNum) this.status = 'learned';
        } else {
          const responseData = await GetChapterNumApi({ ID: linktid || buyID });
          const { ChapterExamNum, ChapterExamDone } = responseData.DataList[0];
          const { todayRemainTopicNum, todayRemainTime, status } = this.allSubjectPlan[keMuID]
          this.status = status;
          this.allSubjectPlan[keMuID].topicNeedNumPerDay = Math.ceil(ChapterExamNum - ChapterExamDone) / this.getTestRemainDays; // 这里获取每天需要做的题目数量
        }
      } else {
        this.status = 'unMake'; // 未指定
      }
      console.log('这里获取每天需要做的题目数量', +ChapterExamNum)
    },
    
    // 获取当天练习题的题目ID
    async getPlanExamIdList() {
      const { keMuID, tiKuID, buyID, linktid } = getCookie('relativeInfo');
      
      if (( this.allSubjectPlan.length && this.allSubjectPlan[keMuID] )) {
        // 当前科目已经存进了Pinia
      } else {
        console.log('111')
        getChapter({
          ID: keMuID,
        }).then(async res => {
          const { KSTKZJList } = res;
          
          KSTKZJList.forEach(item => { item.SJtype = '1' });
          const alreadyHadList = KSTKZJList.filter(i => i.zjIsPay !== '0'); // 筛选已有的章节
          const isEmpty = KSTKZJList.every(item => item.lnztNum === '0' && item.mnktNum === '0');
          
          if (!isEmpty) {
            const { KSTKList } = await getChapterTopicInfo({ id: keMuID });
            const newArr = [...KSTKList, ...KSTKZJList];
            
            newArr.forEach(item => { item.SJtype = item.SJtype || '1' });
            let index = Math.floor(Math.random() * newArr.length);
            
            bannerGetTopic({ tid: newArr[index].id }).then(res => {
              let examTypeArr = [];
              let examNum = 0;
              
              res.tmtypeNum.forEach(item => {
                examTypeArr.push(item.TmType);
                examNum += +( item.StNum )
              })
              
              this.onGetExamId(examNum, examTypeArr, newArr[index].id)
            })
            console.log('dddddddddddddddddd', newArr, newArr[index].id, index)
          }
        })
      }
    },
    
    onGetExamId(examNum, examTypeArr, tid) {
      getExamId({
        examNum, examType: examTypeArr, typeId: tid, doType: 'random',
      }).then(res => {
        if (res.S === '1') {
          console.log('ssssssssssssssss', res.examIdList)
        }
      })
    },
  }, getters: {
    // 考试剩余天数
    getTestRemainDays: (state) => {
      const currentDayTimestamp = +new Date();
      const testDayTimestamp = +new Date(state.testDate);
      
      return Math.floor(( testDayTimestamp - currentDayTimestamp ) / ( 24 * 3600 * 1000 ));
    },
  }, persist: true,
})

