<template>

  <el-row class="mock-room">
    <el-col :lg="{span:5, offset:1}" :xl="{span:4, offset:2}" class="hidden-md-and-down">
      <LazyExamChapter />
    </el-col>

    <el-col :lg="16" :md="22" :sm="22" :xl="16" class="tab-box">
      <LazyExamMenu />
      <div class="box">
        <div class="tab__top">
<!--          <el-button :icon="modalShow ? Fold : Expand" class="let__btn hidden-lg-and-up" @click="toggleShow"/>-->
          <div>
            <h2>{{ selectObj.tname  }}</h2>
            <p class="mode-tips">练习模式：边做题边出答案 　　背题模式：直接显示答案，无需做题，无做题记录</p>
          </div>

          <div class="study__banner">
            <div class="banner__left">
              <img class="left-img" src="https://jscdn.ppkao.com/shop_tiku/assets/images/study_plan.png">
              <p class="hr"></p>
              <p class="tips">{{ tips }}</p>
            </div>
            <button class="study-btn" @click="state.isShowPlanModal = true">学习</button>
          </div>
        </div>

        <LazyExamTopicTables
          :tableData="tableData"
          @getTopicNumAndType="getTopicNumAndType"
          @onRowClick="onRowClick"
        />
<!--        <el-skeleton :loading="!state.tableDataIsLoaded" :rows="5" animated/>-->
      </div>
    </el-col>
  </el-row>

  <el-dialog v-model="isShowFilterModal" width="40%">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <h4 :id="titleId" :class="titleClass" style="font-size: 20px">做题筛选</h4>
      </div>
    </template>
    <div class="topic__filter">
      <div class="topic__classify">
        <p class="title">试题分类</p>
        <div class="btn__container">
          <div
            v-for="{name, type} in state.doTypeArr"
            @click="state.currentDoType = type"
          >
            <button :class="{'selected':type === state.currentDoType }">{{ name }}</button>
          </div>
        </div>
      </div>
      <div class="topic__type">
        <p class="title">题目类型</p>
        <div class="btn__container">
          <div
            v-for="({tmtype, num, isSelected}, index) in state.topicNumList"
            @click="state.topicNumList[index].isSelected = !state.topicNumList[index].isSelected"
          >
            <button :class="{'selected': isSelected }">{{ getTopicType(tmtype) }}（{{ num }}）</button>
          </div>
        </div>
      </div>
      <div class="topic__num">
        <p class="title">做题数量</p>
        <div class="btn__container">
          <div
            v-for="item in state.numArrList"
            @click="state.currentDoNum = item"
          >
            <button :class="{'selected': item === state.currentDoNum }">{{ item }}</button>
          </div>
        </div>
      </div>

      <el-button :loading="state.isLoading" class="start" @click="startDoExercise">开始做题</el-button>
    </div>
  </el-dialog>

  <!--  <PracticeModal-->
  <!--    :isShowPlanModal="state.isShowPlanModal"-->
  <!--    @update:isShowPlanModal="() => state.isShowPlanModal = !state.isShowPlanModal"-->
  <!--  />-->
</template>
<script setup>
import { getChapter, getTopicTypeAndNum, getExamId, getChapterTopicInfo} from '~/api/exam'

import { useTopicStore } from '@/stores/topic';
import { useConfigStore } from '@/stores/config';
import { usePracticeStore } from '@/stores/practice';
// import PracticeModal from "../practice/PracticeModal/PracticeModal.vue";
import 'element-plus/theme-chalk/display.css'
const practiceStore = usePracticeStore();
const topicStore = useTopicStore();

const router = useRouter();
const route = useRoute();
const { id } = route.params;
const modalShow = ref(false) // 章节树弹窗
const selectObj = ref({ tname: '请先选择章节' }); // 当前选中的节点
const tableData = ref([]); // 表格数据
const isShowFilterModal = ref(false); // 是否显示做题筛选弹窗
const state = reactive({
  doTypeArr: [ // 做题类型
    { name: '未做题', type: 'notdo' },
    { name: '已做题', type: 'doneExam' },
    { name: '做错题', type: 'errorExam', }
  ],
  topicNumList: [], // 题目类型
  currentDoType: 'notdo', // 当前做题类型
  numArrList: [20, 50, 100], // 做题数量
  currentDoNum: 20, // 当前做题数量
  currentExamId: '', // 当前试卷id
  currentExamMode: 'practice', // practice 练习模式   look 看题模式
  currentClickChapterInfo: {}, // 当前点击的章节信息
  treeDataIsLoaded: false, // 章节树数据是否加载
  tableDataIsLoaded: false, // 表格数据是否加载
  isLoading: false, // 是否正在加载
  isShowPlanModal: false, //是否显示z制定学习计划弹窗
  defaultExpand: [], // 默认展开数组
})
const tips = computed(() => {
  return practiceStore.status === 'unMake' ? '制定专属学习计划，规划学习进步更快哦' : practiceStore.status === 'unLearn' ? '今日还有234道题，20分钟学习时间未完成哦' : '今日已完成，明天继续加油哦!'
})// 制定专属学习计划

const onRowClick = (row) => {
  console.log(row)
}

const close = () => state.isShowPlanModal = false;

const startDoExercise = async () => {
  state.isLoading = true
  const { currentDoType: doType, currentDoNum:examNum, topicNumList, currentExamId: typeId, currentExamMode, currentClickChapterInfo } = state;
  let { linktid, tname } = currentClickChapterInfo;

  linktid ||= currentClickChapterInfo.tn === getCookie('linktid') ? getCookie('linktid') : ''; // 保存章节关联id
  setCookie('chapterName', tname); // 保存章节名称

  const examTypeArr  = topicNumList.filter(item => item?.isSelected).map(item => item.tmtype); // 获取选中的题目类型

  try {
    const { examIdList, S } = await getExamId({doType, typeId, examType: examTypeArr.join(','), examNum})
    if (S === '1') {
      topicStore.currentTopicList = []; // 跳转前清除store中存的状态
      isShowFilterModal.value = false;  // 关闭做题筛选弹窗
      setCookie('examIdList', examIdList); // 保存试卷id
      setCookie('mode', currentExamMode); // 保存练习模式
      router.push({
        path: '/home/exam',
        query: {
          examIdList,
          linktid: linktid || '',
          mode: currentExamMode,
          chapterName: tname
        }
      })
    }
    state.isLoading = false
  } catch ( err ) {
    state.isLoading = false
    throw err;
  }
}

const toggleShow = () => {
  if (!modalShow.value) {
    handleInit();
  }
  modalShow.value = !modalShow.value
}
// 初始化获取数据
const handleInit = () => {
  topicStore.getTreeListData(id);  // 需要每次都获取科目然后判断权限
}
// 点击节点
const handleNodeClick = async item => {
  state.tableDataIsLoaded = false; // 表格数据关闭加载
  tableData.value = []; // 表格数据清空
  selectObj.value = item; // 保存当前点击的节点
  let allList = []; // 保存所有的数据
  // 正常调用接口
  const { S, KSTKZJList } = await getChapter({ id: item.id || topicStore.currentSubject.id });

  if (S === '1') {
    allList = KSTKZJList;
  } else if (item.linktidisleve === '0') {// linktidisleve === '0' 就是有关联题库
    setCookie('linktid', item.linktid); // 保存关联id
    const { S, KSTKZJList } = await getChapter({ id: item.linktid });// 获取关联题库
    if (S === '1') allList = KSTKZJList;
  } else {// 既没有关联id当前id 又没有数据的情况下就查自己本身
    const { KSTKList, S } = await getChapterTopicInfo({ id: item.id || topicStore.currentSubject.id })
    if (S === '1') {
      allList = KSTKList;
    }
  }
  if (item && !item.tname && allList.length > 0) { // 有点击章节的情况
    selectObj.value = { tname: topicStore.currentSubject.tname };// 设置选中的节点
  }

  allList.forEach(item => {
    const { tname, ChapterExamDone: chapterExamDone, ChapterExamRight, stnum: topicNum } = item
    let nodeName = tname;
    const chapterExamDoneNum = Number(chapterExamDone);
    const topicNumNum = Number(topicNum);

    // 计算正确率
    let accuracy = chapterExamDoneNum ? ((Number(ChapterExamRight) / chapterExamDoneNum) * 100).toFixed(0) + '%' : '0%';
    // 计算进度
    let progress = chapterExamDoneNum ? ((chapterExamDoneNum / topicNumNum ) * 100).toFixed(0) + '%' : '0%';
    // 如果正确率为0就设置为0
    if (ChapterExamRight === '0') accuracy = '0%';

    Object.assign(item, { nodeName, accuracy, progress, topicNum, chapterExamDone });// 设置节点信息
    if (item.isleve === '1' && item.linktidisleve === '1') {// 如果是章节
      Object.assign(item, { leaf: true }); // 设置节点为叶子节点
    }
    tableData.value = allList.filter(item => item.zjIsPay !== '0'); // 筛选了一下 隐藏了没开通的章节
  })
  state.tableDataIsLoaded = true; // 表格数据开启加载
}

//  获取当前章节题目的数量
const getTopicNumAndType = async (row, type) => {
  state.currentClickChapterInfo = row;
  const { S, tmtypeNum } = await getTopicTypeAndNum({ tid: row.id });

  if (S === '1') {
    state.topicNumList = tmtypeNum.map(item => ({ ...item, isSelected: false }));
    state.currentExamMode = type;
    state.currentExamId = row.id;
    isShowFilterModal.value = true;
  }
};

// 获取科目信息
topicStore.getTikuData(id);

watch(
  () => topicStore.currentSubject,
  () => handleNodeClick({}), // 获取右侧表格的数据
);
</script>

<style lang="scss" scoped>
@use "@/assets/styles/form.scss";

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

:deep(.drawer-bg ) {
  .tree__label {
    color: #1a1f31;
  }

  .custom-tree-node:hover {
    .tree__label {
      color: #fff;
    }
  }
}

.tab-box {
  margin-left: 34px;
  .box {
    margin-bottom: 30px;
    border-radius: 10px;
    //min-height: 680px;
    padding: 24px;
    background: #fff;
    min-height: calc(100vh - 176px);
    box-sizing: border-box;
  }

  .tab__top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .let__btn {
      margin-right: 20px;
    }

    .study__banner {
      background-image: url('https://jscdn.ppkao.com/shop_tiku/assets/images/study_banner1.png');
      background-size: 100% 100%;
      width: 100%;
      height: 112px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: -23px 0 18px 0;

      .banner__left {
        display: flex;
        align-items: center;
        margin: 30px 0 0 18.8%;
        width: 70%;

        .hr {
          width: 2px;
          height: 46px;
          background: #402417;
          border-radius: 1px;
          margin: 0 2%;
        }

        .tips {
          font-size: 16px;
          font-weight: 400;
          color: #7F685D;
          margin-right: 50px;
        }
      }

      .study-btn {
        background-image: url('https://jscdn.ppkao.com/shop_tiku/assets/images/study_button.png');
        background-size: 100% 100%;
        width: 88px;
        height: 47px;
        font-size: 20px;
        font-weight: bold;
        color: #271207;
        line-height: 47px;
        text-align: center;
        margin: 30px 4.8% 0 0;
      }
    }
  }

  h2 {
    font-size: 26px;
    line-height: 24px;
  }

  .mode-tips {
    margin-top: 13px;
    color: #A7A7BB;
    font-size: 16px;
    margin-bottom: 26px;
  }
}

.treeList__box {
  width: 100%;
  height: 210px;
  overflow: auto;
  background: #F4F5F9;
  border-radius: 10px;
}

.input__box {
  width: 100%;
  box-sizing: border-box;
  padding: 14px 20px 0;

  .input {
    padding: 13px 18px;
    height: 40px;
    background: #F4F5F9;
    border-radius: 10px;
    box-sizing: border-box;
    font-size: 14px;
    width: 100%;
  }
}

.mock-room {
  position: relative;
  max-width: var(--web-wdith);
  margin: 30px auto 0;
  .top__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
  }
}

.topic__filter {

  .topic__classify, .topic__type, .topic__num {
    padding-top: 36px;

    .title {
      @include font(14px, #A7A7BB, bold);
    }

    .btn__container {
      display: flex;
      flex-wrap: wrap;

      div {
        height: 36px;
        width: 25%;
        margin-top: 15px;
      }

      button {
        box-sizing: border-box;
        width: 90%;
        height: 44px;
        background: #FFFFFF;
        border: 2px solid #EAEDEF;
        border-radius: 10px;
        font-size: 14px;
        color: #000000;
        line-height: 24px;
      }

      .selected {
        box-sizing: border-box;
        color: #FFFFFF;
        background: var(--primary-color);
        border: none;
      }
    }
  }

  .start {
    width: 90%;
    height: 53px;
    background: var(--primary-color);
    border-radius: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    display: block;
    margin: 90px auto 0;
  }
}

.make__plan {
  background-image: url("https://jscdn.ppkao.com/shop_tiku/assets/images/study_modal.png");
  width: 599px;
  height: 554px;
}

</style>
