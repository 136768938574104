import { getChapter, getChapterTopicInfo, getUserSubject, savePaperAnswer } from '~/api/exam';

import { defineStore } from 'pinia'

export const useTopicStore = defineStore('topic', {
  state: () => {
    return {
      chapterLoad: false, //当前章节是否加载完毕
      chapterData:[], // 章列表
      sectionLoad: false, // 当前节请求状态
      sectionData: [], // 节列表
      
      currentTopicList: [], //当前完成的题目数组
      userObjectList: [], // 用户当前题库下的所有科目
      currentSubject: {}, // 当前科目信息
      treeListData: [], // 当前章节树数据
      chapterTreeExpandIdArr: [], //当前章节数默认展开的id 数组
      chapterId: '', // 当前章节id
      currentSubjectDate: '', // 当前科目有效期
      treePaperListData: [], // 试卷树数据
      paperTreeExpandIdArr: [], // 试卷数默认展开的父节点id数组
      paperTableData: [], // 试卷表格数据
      paperTableDataIsLoader: true, // 试卷表格是否加载完毕
      paperTreeListDataIsLoaded: false, // 试卷树是否加载完毕
      userSubjectList: [], // 用户当前题库下的所有科目, 科目科目科目!!!
      paperResultArr: [], //做试卷存的答案数组，有十条才提交答案
      isShowCorrectDialog: false, // 是否显示正确答案
      allPaperIdList: [], // 所有试卷id
    }
  },
  actions: {
    handleCurrentTopicList(topicInfo) {
      const { index, answer, isRight, userAnswer, isShowAnswer, isAnLi, bigIndex } = topicInfo;
      console.log(isAnLi, '是否案例', index, 'bigIndex:', bigIndex)
      if (isAnLi || isAnLi === 0) {
        const json = { isRight, answer, userAnswer, showAnswer: isShowAnswer };
        
        if (!this.$state.currentTopicList[isAnLi]) {
          this.$state.currentTopicList[isAnLi] = []
          this.$state.currentTopicList[bigIndex] = []
        }
        if (bigIndex) this.$state.currentTopicList[bigIndex][index] = json;   // 这里是看题 直接显示案例题答案
        this.$state.currentTopicList[isAnLi][index] = json;   //   这里是做题 直接显示案例题答案
        
        console.log('this.$state.currentTopicList', this.$state.currentTopicList[isAnLi])
      } else {
        this.$state.currentTopicList[index] = {
          userAnswer, answer, isRight, showAnswer: isShowAnswer,
        }
      }
    },
    
    // 获取获取科目数据
    async getTikuData(id) {
      this.chapterLoad = true;
      getChapter({ id }).then((res) => {
        if (res.S === '1') {
          this.chapterData = res.KSTKZJList;
          console.log('[章列表]:', res.KSTKZJList);
          this.sectionLoad = true;
          getChapter({ id: res.KSTKZJList[0].id }).then(({ S, KSTKZJList })=>{
            if (S === '1') {
              this.sectionData = KSTKZJList;
              console.log('[节列表]:', KSTKZJList)
            }
          }).finally(this.sectionLoad = false);
        }
      }).finally(this.chapterLoad = false);
      
      // getUserSubject({}).then(async (res) => {
      //   if (res.S === '1') {
      //     const { UserSubjectList } = res;
      //     const hasBuySubject = ( UserSubjectList.filter((item) => ( item.IsPayment === 'True' && Date.parse(item.EndDate) > new Date().getTime() )) )
      //     if (Array.isArray(hasBuySubject) && hasBuySubject.length > 0) {
      //       hasBuySubject.sort((a, b) => {
      //         if (a.IsDefault === 'True' && b.IsDefault === 'False') {
      //           return -1;
      //         }
      //         if (a.IsDefault === 'False' && b.IsDefault === 'True') {
      //           return 1;
      //         }
      //         return 0;
      //       });
      //       localStorage.setItem('hasBuySubject', JSON.stringify(hasBuySubject));
      //       const defaultId = hasBuySubject[0].isflag === '3' ? hasBuySubject[0].tkid : hasBuySubject[0].Tid; // 获取IsDefault为True的科目进行下级科目展示
      //       const { KSTKZJList } = await getChapter({ id: defaultId });
      //       hasBuySubject.unshift(...KSTKZJList);
      //       this.userSubjectList = hasBuySubject; // 设置易购科目
      //       // 判断当前id 存在就获取id 传入当前设置科目的方法
      //       this.subjectInitialize(hasBuySubject.shift(), false, hasBuySubject[0])
      //     }
      //   }
      // })
    },
    
    // 初始化
    // item 这个是当前科目的信息
    // firstChapterInfo 这个是当前用户第一个科目的第一个章节
    subjectInitialize(list, isPaper) {
      const firstChapterInfo = list[0];
      isPaper ? this.onGetPaperTreeList() : this.onGetChapter(firstChapterInfo)
    },
    
    // tnId 是否有父级id， 如果是有父级id 就是章节 不是题库 需要做其他处理
    async setCurrentSubject(item, setPaper, tnId) {
      console.log(item, '切换了', tnId)
      
      if (!tnId) {
        const id = item.isflag === '3' ? item.tkid : item.Tid || item.id
        setCookie('chapterId', id);
        this.chapterId = id
        this.currentSubject = item;
        // this.currentSubjectDate = item.EndDate.slice(0,10);
        this.paperTreeExpandIdArr = [];
        this.chapterTreeExpandIdArr = [];
        // setCookie('currentSubjectDate', this.currentSubjectDate)
        setCookie('currentSubjectInfo', item)
        setPaper ? this.onGetPaperTreeList() : this.onGetChapter(item)
      }
      // 下面就是切换章节的处理
      // const {KSTKList, S } = await getChapterTopicInfo({id: tnId});
    },
    
    // 获取试卷树
    async onGetPaperTreeList() {
      let { KSTKZJList, S } = await getChapter({ id: getCookie('questionBankId') });
      let haveNoPaper;  // 是否有一套试卷 没有就调用上级的id去查试卷
      
      if (S === '1') {
        haveNoPaper = KSTKZJList.every(item => item.lnztNum === '0' && item.mnktNum === '0');
        
        if (haveNoPaper) {
          //一套试卷都没有 拿父级id去继续查
          const { KSTKList, S } = await getChapterTopicInfo({ id: getCookie('questionBankId') });
          
          if (S === '1') KSTKZJList.unshift(KSTKList[0])
        }
        
        // 判断tkIsPay 是因为如果章节都没试卷需要获取题库信息进行填充
        KSTKZJList = KSTKZJList.filter(item => ( ( ( +item.mnktNum ) || ( +item.lnztNum ) ) && ( item.zjIsPay === '1' || item.tkIsPay === '1' ) ));
        // 过滤掉没有试卷且没有购买的章节
        KSTKZJList.forEach(item => {
          if (!this.paperTreeExpandIdArr.length) this.paperTreeExpandIdArr.push(item.id);
          //往子集填充 试卷数量
          if (( +item.mnktNum ) || ( +item.lnztNum )) {
            item.erzi = [{
              tname: `模拟试题（${ +item.mnktNum }）`,
              leaf: false,
              paperTid: item.kstid,
              type: '1',
              num: +item.mnktNum,
            }, {
              tname: `历年真题（${ +item.lnztNum }）`,
              leaf: false,
              paperTid: item.kstid,
              type: '2',
              num: +item.lnztNum,
            }]
          }
        })
        console.log(KSTKZJList, 'KSTKZJListKSTKZJListKSTKZJList', this.paperTreeExpandIdArr)
        this.treePaperListData = KSTKZJList;
      } else {
        this.treePaperListData = []
      }
      this.paperTableData = []; // 切换试卷 获取试卷树都要清空表格数据
      this.paperTreeListDataIsLoaded = true;
      // this.onGetAllSimulateExamPaperIdList(KSTKZJList);
    },
    
    async onGetChapter(firstChapterInfo) {
      this.treeListData = [];
      
      const { S, KSTKZJList } = await (firstChapterInfo.linktidisleve === '0' ? getChapter({ id: firstChapterInfo.linktid }) : getChapter({ id: firstChapterInfo.id || this.getChapterId }));
      
      if (S === '1') {
        // 设置左边树状结构
        
        this.treeListData = KSTKZJList.map(item => {
          // isleve === 1 就是没有子集， linktidisleve === '1' 就是没有关联科目
          Object.assign(item, { leaf: item.isleve === '1' && item.linktidisleve === '1' })
          if (item.zjIsPay !== '0' && ( item.isleve === '0' || item.linktidisleve === '0' ) && !this.chapterTreeExpandIdArr.length) this.chapterTreeExpandIdArr.push(item.id)
          return item;
        }).filter(item => item.zjIsPay === '1'); // 筛选了一下 隐藏了没开通的章节
        console.log('[题库章]:', this.treeListData)
        this.treeListDataIsLoaded = true;
      }
    },
    
    onOpenCorrectDialog() {
      this.isShowCorrectDialog = !this.isShowCorrectDialog
    },
    
    async onPaperResultArr(paperSubmitInfo) {
      const { paperId, linktid, submitStr } = paperSubmitInfo;
      
      this.$state.paperResultArr.push(submitStr);
      console.log('state.paperResult:', this.$state.paperResultArr)
      if (this.$state.paperResultArr.length >= 10) {
        await savePaperAnswer({ paperId, linktid, postStr: this.$state.paperResultArr.join('[|]') })
        
        this.$state.paperResultArr = [];
      }
    },
  },
  
  getters: {
    getChapterId(state) {
      return state.chapterId || getCookie('chapterId')
    },
  },
})
